import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "toolbar",
  id: "kt_toolbar"
}
const _hoisted_2 = {
  "data-kt-swapper": "true",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}",
  class: "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "h-20px border-gray-200 border-start mx-4"
}
const _hoisted_5 = {
  key: 2,
  class: "breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
}
const _hoisted_6 = { class: "breadcrumb-item pe-3" }
const _hoisted_7 = { class: "breadcrumb-item text-muted" }
const _hoisted_8 = { class: "breadcrumb-item pe-3 text-dark" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "d-flex align-items-center py-1" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vc_leads = _resolveComponent("vc-leads")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_toolbar_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h4", {
              key: 0,
              class: "d-flex align-items-center text-dark fw-bolder my-1 fs-3",
              innerHTML: _ctx.title.replace('@SUMMARY@', _ctx.dateSummary)
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.breadcrumbs && _ctx.currentRoute() != 'dashboard')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.breadcrumbs && _ctx.currentRoute() != 'dashboard')
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/dashboard",
                  class: "text-muted text-hover-primary"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Home ")
                  ])),
                  _: 1
                })
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("li", { class: "breadcrumb-item" }, [
                _createElementVNode("span", { class: "bullet bg-gray-200 w-5px h-2px" })
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("li", _hoisted_7, [
                    _createVNode(_component_router_link, {
                      to: { name: item.value },
                      class: "text-muted text-hover-primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
                    _createElementVNode("span", { class: "bullet bg-gray-200 w-5px h-2px" })
                  ], -1))
                ], 64))
              }), 128)),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("span", {
                  innerHTML: _ctx.title.replace('@SUMMARY@', _ctx.dateSummary)
                }, null, 8, _hoisted_9)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (['CustomerLists', 'CustomerDetails'].includes(_ctx.currentRoute()))
          ? (_openBlock(), _createBlock(_component_vc_leads, {
              key: 0,
              topbar: true
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.store.getters.isLoggedin)
            ? (_openBlock(), _createBlock(_component_el_button_group, {
                key: 0,
                class: "ml-4"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolbars, (toolbar, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (
                  _ctx.store.getters.isRoute(toolbar.route) &&
                  _ctx.store.getters.isCondition(toolbar.condition)
                )
                        ? (_openBlock(), _createBlock(_component_el_tooltip, {
                            key: 0,
                            content: toolbar.content,
                            class: "box-item",
                            effect: "light",
                            placement: "bottom-start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                onClick: ($event: any) => (_ctx.myFunction(toolbar.click)),
                                class: "btn btn-icon btn-light-info"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("i", {
                                    class: _normalizeClass(toolbar.icon)
                                  }, null, 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["content"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.currentRoute() == 'CustomerDetails')
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                _createVNode(_component_router_link, {
                  to: { name: 'CustomerLists' },
                  class: "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
                  "data-kt-menu-trigger": "click",
                  "data-kt-menu-placement": "bottom-end",
                  "data-kt-menu-flip": "top-end"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("span", { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }, [
                      _createElementVNode("i", { class: "far fa-arrow-alt-circle-left" })
                    ], -1),
                    _createTextVNode(" Back ")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, [
            (_ctx.currentRoute() == 'editPolicy')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: { name: 'assignedPolicies' },
                  class: "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
                  "data-kt-menu-trigger": "click",
                  "data-kt-menu-placement": "bottom-end",
                  "data-kt-menu-flip": "top-end"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("span", { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }, [
                      _createElementVNode("i", { class: "far fa-arrow-alt-circle-left" })
                    ], -1),
                    _createTextVNode(" Back ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ], 2)
  ]))
}