import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-items-center ms-1 ms-lg-3 me-2"
}
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 icon-search" }
const _hoisted_5 = { class: "d-flex align-items-center icon-notifications" }
const _hoisted_6 = {
  class: "btn btn-icon btn-active-light-primary position-relative cursor-pointer",
  id: "kt_notification_toggle"
}
const _hoisted_7 = {
  class: "d-flex align-items-center cursor-pointer btn-logout-icon",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "card-body p-2" }
const _hoisted_10 = { class: "text-gray-600" }
const _hoisted_11 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_12 = {
  scope: "col",
  class: "p-2 fw-bolder",
  nowrap: ""
}
const _hoisted_13 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_14 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}
const _hoisted_15 = {
  class: "d-inline-block text-truncate",
  style: {"max-width":"150px"}
}
const _hoisted_16 = {
  class: "d-flex align-items-center d-lg-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_17 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_18 = { class: "svg-icon svg-icon-1" }
const _hoisted_19 = { class: "d-flex justify-content-center mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickLinks = _resolveComponent("QuickLinks")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_BaiSearch = _resolveComponent("BaiSearch")!
  const _component_BaiNotification = _resolveComponent("BaiNotification")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_QuickLinks),
      (_ctx.user.is_underwriter === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "btn btn-icon position-relative w-30px h-30px w-md-40px h-md-40px",
              "data-kt-menu-trigger": "click",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = true)),
              "data-kt-menu-attach": "parent",
              "data-kt-menu-placement": "bottom-end",
              "data-kt-menu-flip": "bottom"
            }, [
              _createVNode(_component_el_tooltip, {
                content: "Switch Role",
                placement: "bottom",
                effect: "light"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("i", {
                    class: "bi bi-arrow-left-right",
                    style: {"font-size":"24px"}
                  }, null, -1)
                ])),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "btn btn-icon position-relative btn-search-icon",
          "data-kt-menu-trigger": "click",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SearchDomRef.clear())),
          "data-kt-menu-attach": "parent",
          "data-kt-menu-placement": "bottom-end",
          "data-kt-menu-flip": "bottom"
        }, [
          _createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "light",
            content: "Search",
            placement: "bottom",
            "show-after": 500
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
              ])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_BaiSearch, { ref: "SearchDomRef" }, null, 512)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "light",
            content: "Notifications",
            placement: "bottom",
            "show-after": 500
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("i", {
                class: "fa fa-bell fs-2x",
                "aria-hidden": "true"
              }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("span", {
            class: _normalizeClass(["position-absolute top-0 start-100 translate-middle badge badge-circle", 
                _ctx.notificationCount > 0
                  ? 'badge-primary animation-blink'
                  : 'badge-info'
              ])
          }, _toDisplayString(_ctx.notificationCount), 3)
        ])
      ]),
      _createVNode(_component_BaiNotification),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_popover, {
          placement: "bottom",
          width: "180",
          trigger: "hover",
          "show-after": 200,
          "hide-after": 0
        }, {
          reference: _withCtx(() => [
            _createElementVNode("span", null, [
              _createElementVNode("i", {
                class: "icon-svg icon-logout",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.auth.signOut()))
              })
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("table", _hoisted_10, [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", _hoisted_11, [
                      _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.store.getters.myName), 1)
                    ]),
                    _createElementVNode("tr", _hoisted_13, [
                      _createElementVNode("td", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.store.getters.myEmail), 1)
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/text/txt001.svg" })
          ])
        ])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "Switch Role ?",
      width: "30%",
      "append-to-body": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_vc_button, {
            text: "Cancel",
            className: "btn btn-light me-3",
            onVcClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialogVisible = false)),
            "is-regular": ""
          }),
          _createVNode(_component_vc_button_save, {
            text: 'Confirm',
            loading: _ctx.swtichLoading,
            onClick: _withModifiers(_ctx.switchRole, ["prevent"])
          }, null, 8, ["loading", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "m-5" }, " Do you want to switch your Role ? ", -1))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}