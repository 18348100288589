
import { defineComponent, ref, computed, onMounted, getCurrentInstance } from "vue";
import Etcs from "@/core/services/Callable/Etcs";
import { store } from "@/store";
import { constant } from "@/store/stateless";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "bai-search",
  setup(props) {

    const searchType = ref<number>(1);
    const HTMLSearch = ref();
    let searchList = ref<any>();
    let searchUrl = ref<string>("");
    let isBai = ref<boolean>(false);
    let showLoader = ref<boolean>(false);
    const getSearchResult = () => {
      const keyword = search.value;
      const params = {
        type: searchType.value,
        keyword: keyword,
      };
      const numberOnly = /^[0-9]+$/;
      if (
        !numberOnly.test(keyword) &&
        searchType.value == 1 &&
        keyword.length > 2
      ) {
        searchResult(params);
      } else if (
        searchType.value === 1 &&
        numberOnly.test(keyword) &&
        keyword.length > 3
      ) {
        searchResult(params);
      } else if (
        (searchType.value == 2 || searchType.value == 6) &&
        keyword.length > 3
      ) {
        searchResult(params);
      } else if (
        (searchType.value == 3 ||
          searchType.value == 4 ||
          searchType.value == 5) &&
        keyword.length > 0
      ) {
        searchResult(params);
      } else {
        searchList.value = [];
      }
    };

    const router = useRouter()

    const getSearchEnter = () => {
      const keyword = search.value;
      const params = {
        type: searchType.value,
        keyword: keyword,
      };
      searchResult(params);
      // searchToDown();
    };
    onMounted(() => {
      // console.log({ search: HTMLSearch });
    })
    const searchTypes = ref([
      { value: 4, label: "Customer ID" },
      { value: 1, label: "General" },
      { value: 3, label: "Lead ID" },
      { value: 6, label: "Policy No" },
    ]);

    const searchResult = (params) => {
      showLoader.value = true;
      Etcs.globalSearch(params).then((data) => {
        showLoader.value = false;
        searchList.value = data.result;
        searchUrl.value = data.url;
        isBai.value = data.isBai;
      });
    };
    const getSearchUrl = (searchUrl, data) => {
      if (searchUrl == "policy/edit" && !data?.policy_purchase) {
        return "/customers/details/" + data.customer_id;
      }
      const permissions = store.getters.getUserPermissions;
      console.log("searchUrl.value", searchUrl);
      if (searchUrl == "customers/details") {
        if (searchType.value == 3) {
          return "/" + searchUrl + "/" + data.id ;
          // + "?lead_id=" + search.value;
        } else {
          return "/" + searchUrl + "/" + data.id;
        }
      }
      if (
        searchUrl == "policy/edit" &&
        !permissions.is_marketing
      ) {
        if (data?.policy_purchase) {
          const policyId = data?.policy_purchase.id;
          if (policyId == undefined) {
            return "/customers/details/" + data.id;
          }
          if (searchUrl == "customers/details/") {
            return "/customers/details/" + data.customer_id;
          }
          return "/" + searchUrl + "/" + policyId;
        }
        if (
          !data?.policy_purchase &&
          (permissions.is_admin ||
            permissions.is_sales_agent ||
            permissions.is_team_leader ||
            permissions.is_manager ||
            permissions.is_accountant)
        ) {
          if (searchType.value == 3) {
            return "/customers/details/" + data.id + "?lead_id=" + search.value;
          } else {
            return "/customers/details/" + data.id;
          }
        }
      } else {
        if (searchType.value == 3) {
          return "/customers/details/" + data.id + "?lead_id=" + search.value;
        } else {
          return "/customers/details/" + data.customer_id;
        }
      }
      return "";
    };

    const goToNav = (item, url) => {

      const permissions = store.getters.getUserPermissions;
      const policyStatus = item.status

      const policyCreatorUrl = "policy/edit";
      const policyListUrl = item.status == 4 ? "policy/view" :  "policy/view";
      const policyCreatorGroupUrl = "policy/group/edit";
      const policyListGroupUrl = item.status == 4 ? "policy/group/view" :  "policy/group/view";

      const customerUrl = "customers/details";
      if(item && item.lead_status_id && item.lead_status_id == 5) {
        if(permissions.is_underwritter) {
          if(item.is_group && item.is_group == 1) {
            return `/#/policy/group/completed?page=1&sf=${search.value}`
          } else {
            return `/#/policy/completed?page=1&sf=${search.value}`
          }
        }

        return `/#/${customerUrl}/${item.customer_id}`
      }

      if(item.is_group && item.is_group == 1 )
        url = policyCreatorGroupUrl;
        
      if (url == policyCreatorUrl) {
        // if (item.policy_id == null && item.invoice_status == 1) {
        //   return `/${customerUrl}/${item.customer_id}`;
        // }
        if (item.policy_id == null ) {
          return `/#/${customerUrl}/${item.customer_id}`;
        }
        return (permissions.is_underwritter || permissions.is_admin) ? `/#/${policyCreatorUrl}/${item.policy_id}` : `/#/${policyListUrl}/${item.policy_id}`;

      }else if (url == policyCreatorGroupUrl) {
        // if (item.policy_id == null && item.invoice_status == 1) {
        //   return `/${customerUrl}/${item.customer_id}`;
        // }
        if (item.policy_id == null ) {
          return `/#/${customerUrl}/${item.customer_id}`;
        }
        return (permissions.is_underwritter || permissions.is_admin) ? `/#/${policyCreatorGroupUrl}/${item.policy_id}` : `/#/${policyListGroupUrl}/${item.policy_id}`;
      } else {
        if (searchType.value == 3) {
          return `/#/${customerUrl}/${item.customer_id}?lead_id=${item.car_lead_id}`;
        }
        return `/#/${customerUrl}/${item.customer_id}`;
      }
    };

    const searchToDown = () => {
      const inputs: Array<any> = Array.from(
        document.querySelectorAll(".search-result")
      );
      inputs[0].focus();
    };

    const invoiceDisabled = (result) => {
      if(result && result.lead_status_id && result.lead_status_id == 5 && result.status == 4) {
        return false
      }

      if(result && result.quote_ref_no && !result.policy_id) return true

      if (
        result.invoice_id ||
        isBai.value ||
        (userPermissions.value.is_underwritter || userPermissions.value.is_accountant)
      ) {
        if (
          (result.invoice_status != 2 ||
            result.invoice_status == null ||
            result.policy_id == null) && (userPermissions.value.is_underwritter || userPermissions.value.is_accountant)
        ) {

          return true;
        }
      }
      return false;
    };

    const pressdown = (event) => {
      const inputs: Array<any> = Array.from(
        document.querySelectorAll(".search-result")
      );
      const index = inputs.indexOf(event.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    };
    const pressup = (event) => {
      const inputs: Array<any> = Array.from(
        document.querySelectorAll(".search-result")
      );
      const index = inputs.indexOf(event.target);

      if (index < inputs.length) {
        inputs[index - 1].focus();
      }
    };

    const search = ref("");

    const reset = () => {
      search.value = "";
      getSearchResult();
      toFirst()
    };

    
    const clear = () => {
      searchType.value = 1
      search.value = "";
      getSearchResult();
      toFirst()
    }

    const status = (e,invoice) => {

      const invoice_status = invoice.invoice_status;

      if (e == null || invoice_status != 2) {
        // return "Payment pending";
        // const invoiceStatus = constant.invoice_status;
        // const valStatus = invoiceStatus.find((x: any) => x.value === invoice_status);
        // if(invoice_status == 3 && invoice.declined_by == 1)
          return 'PNG';

        // return valStatus.label ?? '-';
      }
      // const statuses = store.getters.getPolicyStatuses;
      const statuses = constant.policy_statuses;
      const val = statuses.find((x: any) => x.value === e);
      return val.label ?? '-';
    };

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        const focusHere: any = inst?.refs?.focusHere
        focusHere.focus()
      }, 500);
    }
    return {
      clear,
      toFirst,
      HTMLSearch,
      userPermissions,
      isBai,
      invoiceDisabled,
      status,
      goToNav,
      getSearchEnter,
      reset,
      search,
      pressdown,
      pressup,
      searchToDown,
      searchTypes,
      searchType,
      getSearchResult,
      searchList,
      searchResult,
      searchUrl,
      getSearchUrl,
      showLoader,
    };
  },
});
