import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_activities",
  class: "bg-white",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "activities",
  "data-kt-drawer-activate": "true",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'300px', 'lg': '900px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_activities_toggle",
  "data-kt-drawer-close": "#kt_activities_close"
}
const _hoisted_2 = { class: "card shadow-none" }
const _hoisted_3 = {
  class: "card-header",
  id: "kt_activities_header"
}
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-active-light-primary me-n5",
  id: "kt_activities_close"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = {
  class: "card-body position-relative",
  id: "kt_activities_body"
}
const _hoisted_8 = {
  id: "kt_activities_scroll",
  class: "position-relative scroll-y me-n5 pe-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-wrappers": "#kt_activities_body",
  "data-kt-scroll-dependencies": "#kt_activities_header, #kt_activities_footer",
  "data-kt-scroll-offset": "5px"
}
const _hoisted_9 = { class: "timeline" }
const _hoisted_10 = {
  class: "card-footer py-5 text-center",
  id: "kt_activities_footer"
}
const _hoisted_11 = {
  href: "#",
  class: "btn btn-bg-white text-primary"
}
const _hoisted_12 = { class: "svg-icon svg-icon-3 svg-icon-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Item1 = _resolveComponent("Item1")!
  const _component_Item2 = _resolveComponent("Item2")!
  const _component_Item3 = _resolveComponent("Item3")!
  const _component_Item4 = _resolveComponent("Item4")!
  const _component_Item5 = _resolveComponent("Item5")!
  const _component_Item6 = _resolveComponent("Item6")!
  const _component_Item7 = _resolveComponent("Item7")!
  const _component_Item8 = _resolveComponent("Item8")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title fw-bolder text-dark" }, "Activity Logs", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Item1),
            _createVNode(_component_Item2),
            _createVNode(_component_Item3),
            _createVNode(_component_Item4),
            _createVNode(_component_Item5),
            _createVNode(_component_Item6),
            _createVNode(_component_Item7),
            _createVNode(_component_Item8)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("a", _hoisted_11, [
          _cache[1] || (_cache[1] = _createTextVNode(" View All Activities")),
          _createElementVNode("span", _hoisted_12, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
          ])
        ])
      ])
    ])
  ]))
}