import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3",
  "data-kt-menu": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"menu-item px-3\"><div class=\"menu-content text-muted pb-2 px-3 fs-7 text-uppercase\"> Contacts </div></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_users_search\"> Add Contact </a></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link flex-stack px-3\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_invite_friends\"> Invite Contacts <i class=\"fas fa-exclamation-circle ms-2 fs-7\" data-bs-toggle=\"tooltip\" title=\"Specify a contact email to send an invitation\"></i></a></div><div class=\"menu-item px-3\" data-kt-menu-trigger=\"hover\" data-kt-menu-placement=\"right-start\" data-kt-menu-flip=\"left, center, top\"><a href=\"#\" class=\"menu-link px-3\"><span class=\"menu-title\">Groups</span><span class=\"menu-arrow\"></span></a><div class=\"menu-sub menu-sub-dropdown w-175px py-4\"><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"> Create Group </a></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"> Invite Members </a></div><div class=\"menu-item px-3\"><a href=\"#\" class=\"menu-link px-3\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"> Settings </a></div></div></div><div class=\"menu-item px-3 my-1\"><a href=\"#\" class=\"menu-link px-3\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"> Settings </a></div>", 5)
  ])))
}