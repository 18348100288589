import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y my-5 my-lg-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = {
  key: 0,
  class: "menu-icon"
}
const _hoisted_6 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_7 = { class: "menu-title" }
const _hoisted_8 = { class: "menu-link" }
const _hoisted_9 = {
  key: 0,
  class: "menu-icon"
}
const _hoisted_10 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_11 = { class: "menu-title" }
const _hoisted_12 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_13 = ["href", "onClick"]
const _hoisted_14 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeList, (menuItem, j) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
          (menuItem.sub.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: menuItem.route
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("a", {
                      class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                      href: href,
                      onClick: navigate
                    }, [
                      (menuItem.svgIcon || menuItem.fontIcon)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                            (_ctx.asideMenuIcons === 'font')
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                }, null, 2))
                              : (_ctx.asideMenuIcons === 'svg')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                    _createVNode(_component_inline_svg, {
                                      src: `${menuItem.svgIcon}`
                                    }, null, 8, ["src"])
                                  ]))
                                : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate(menuItem.title)), 1)
                    ], 10, _hoisted_4)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            : _createCommentVNode("", true),
          (menuItem.sub.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([{ show: _ctx.hasActiveChildren(menuItem.route) }, "menu-item menu-accordion"]),
                "data-kt-menu-sub": "accordion",
                "data-kt-menu-trigger": "click"
              }, [
                _createElementVNode("span", _hoisted_8, [
                  (menuItem.svgIcon || menuItem.fontIcon)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                        (_ctx.asideMenuIcons === 'font')
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                            }, null, 2))
                          : (_ctx.asideMenuIcons === 'svg')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                _createVNode(_component_inline_svg, {
                                  src: `${menuItem.svgIcon}`
                                }, null, 8, ["src"])
                              ]))
                            : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.translate(menuItem.title)), 1),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass([{ show: _ctx.hasActiveChildren(menuItem.route) }, "menu-sub menu-sub-accordion"])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.sub, (item2, k) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                      (item2.title)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_router_link, {
                              to: item2.route
                            }, {
                              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                                _createElementVNode("a", {
                                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                                  href: href,
                                  onClick: navigate
                                }, [
                                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-bullet" }, [
                                    _createElementVNode("span", { class: "bullet bullet-dot" })
                                  ], -1)),
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.translate(item2.title)), 1)
                                ], 10, _hoisted_13)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "menu-item" }, [
        _createElementVNode("div", { class: "menu-content" }, [
          _createElementVNode("div", { class: "separator mx-1 my-4" })
        ])
      ], -1))
    ])
  ], 512))
}