import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timeline-item" }
const _hoisted_2 = { class: "timeline-icon symbol symbol-circle symbol-40px" }
const _hoisted_3 = { class: "symbol-label bg-light" }
const _hoisted_4 = { class: "svg-icon svg-icon-2 svg-icon-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "timeline-line w-40px" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com010.svg" })
        ])
      ])
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"timeline-content mb-10 mt-n1\"><div class=\"pe-3 mb-5\"><div class=\"fs-5 fw-bold mb-2\"> New case <a href=\"#\" class=\"text-primary fw-bolder me-1\">#67890</a> is assigned to you in Multi-platform Database Design project </div><div class=\"overflow-auto pb-5\"><div class=\"d-flex align-items-center mt-1 fs-6\"><div class=\"text-muted me-2 fs-7\">Added at 4:23 PM by</div><a href=\"#\" class=\"text-primary fw-bolder me-1\">Alice Tan</a></div></div></div></div>", 1))
  ]))
}