import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "kt_notification",
  class: "bg-body",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "kt_notification",
  "data-kt-drawer-activate": "true",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'350px', 'lg': '475px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_notification_toggle",
  "data-kt-drawer-close": "#kt_notification_close"
}
const _hoisted_2 = { class: "card shadow-none w-100" }
const _hoisted_3 = {
  class: "card-header",
  id: "kt_notification_header"
}
const _hoisted_4 = { class: "card-title fw-bolder text-gray-700" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  class: "card-body",
  id: "kt_notification_body",
  style: {"overflow-x":"auto"}
}
const _hoisted_7 = { class: "mb-0" }
const _hoisted_8 = { class: "mb-7" }
const _hoisted_9 = { class: "container p-0" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-6 pull-right" }
const _hoisted_12 = {
  key: 0,
  style: {"height":"620px","overflow":"auto"}
}
const _hoisted_13 = { class: "d-flex flex-stack" }
const _hoisted_14 = { class: "d-flex flex-column" }
const _hoisted_15 = { class: "d-flex align-items-center mb-1" }
const _hoisted_16 = { class: "fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "fs-7 text-muted" }
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, " Notifications(" + _toDisplayString(_ctx.notificationCount) + ") ", 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.makeNotificationRead())),
            class: "btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6",
            id: "kt_notification_close"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "icon-svg icon-close" }, null, -1)
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-sm-6" }, [
                  _createElementVNode("span", { class: "mb-0 fs-3 me-3 fw-bold" }, "Recent"),
                  _createElementVNode("span", { class: "mb-0 text-gray-600" }, "Last 3 days")
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_router_link, {
                    to: "/notification/list",
                    class: "fw-bold"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("View All")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          (_ctx.notificationList && _ctx.notificationList.length > 0)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationList, (notification) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["p-5 rounded text-dark fw-bold mw-lg-400px text-start py-4 px-6 mb-5", 
                notification.is_click == 0
                  ? 'bg-light-primary'
                  : 'bg-light-info'
              ]),
                    key: notification.id
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_router_link, {
                              target: "_blank",
                              to: notification.url ? notification.url : '#',
                              class: "text-dark fw-bold"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  onClick: ($event: any) => (_ctx.updateIsClick(notification))
                                }, _toDisplayString(notification.description), 9, _hoisted_17)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(notification.show_date), 1)
                      ])
                    ])
                  ], 2))
                }), 128)),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, "Loading..."))
                  : _createCommentVNode("", true)
              ])), [
                [_directive_infinite_scroll, _ctx.loadDataFromServer]
              ])
            : (_openBlock(), _createBlock(_component_el_empty, {
                key: 1,
                description: "No Records Available"
              }))
        ])
      ])
    ])
  ]))
}