import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center ms-1 ms-lg-3"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex align-items-center ms-1 ms-lg-3"
}
const _hoisted_3 = {
  key: 2,
  class: "d-flex align-items-center ms-1 ms-lg-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.permissions.is_sales_agent ||
      _ctx.permissions.is_manager ||
      _ctx.permissions.is_team_leader
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_tooltip, {
            background: "#f9f9f9",
            effect: "light",
            color: "#000",
            content: 'Dashboard',
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: {
          name: 'dashboard',
        },
                class: "btn-active-color-primary btn-sm dropdown-item ql-background"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("i", { class: "icon-svg icon-dashboard" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_tooltip, {
            background: "#f9f9f9",
            effect: "light",
            color: "#000",
            content: 'Leads & Tasks',
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: {
          name: 'CustomerLists',
        },
                class: "btn-active-color-primary btn-sm dropdown-item ql-background"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "icon-svg icon-customer-list" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_tooltip, {
            background: "#f9f9f9",
            effect: "light",
            color: "#000",
            content: 'Amend Invoice',
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: {
          name: 'invoiceApproved',
        },
                class: "btn-active-color-primary btn-sm dropdown-item ql-background"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "icon-svg icon-amend-invoice-grey" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.permissions.is_underwritter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_tooltip, {
            background: "#f9f9f9",
            effect: "light",
            color: "#000",
            content: 'Policy Assigned',
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: {
          name: 'assignedPolicies',
        },
                class: "btn-active-color-primary btn-sm dropdown-item ql-background"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "icon-svg icon-ql-policy-assigned" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_tooltip, {
            background: "#f9f9f9",
            effect: "light",
            color: "#000",
            content: 'Policy Completed',
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: {
          name: 'completedPolicies',
        },
                class: "btn-active-color-primary btn-sm dropdown-item ql-background"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("i", { class: "icon-svg icon-ql-policy-completed" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.permissions.is_accountant)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_el_tooltip, {
            background: "#f9f9f9",
            effect: "light",
            color: "#000",
            content: 'Approve Invoices',
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: {
          name: 'invoiceApproval',
        },
                class: "btn-active-color-primary btn-sm dropdown-item ql-background"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "icon-svg icon-ql-approve-invoices" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createElementVNode("div", {
      id: "spliter",
      style: {"border-right":"1px solid #00000045","height":"25px","margin-top":"20px","margin-left":"8px"}
    }, null, -1))
  ], 64))
}