import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown p-7 w-450px w-md-450px",
  "data-kt-menu": "true",
  ref: "HTMLSearch"
}
const _hoisted_2 = {
  "data-kt-search-element": "wrapper",
  class: "custom-search-block"
}
const _hoisted_3 = { class: "row uni-search-block d-flex align-items-center me-6" }
const _hoisted_4 = {
  "data-kt-search-element": "toolbar",
  class: "col-5"
}
const _hoisted_5 = { class: "uni-search col-7" }
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = {
  key: 0,
  class: "spinner-loader",
  "data-kt-search-element": "spinner"
}
const _hoisted_8 = { "data-kt-search-element": "results" }
const _hoisted_9 = { class: "scroll-y mh-200px mh-lg-350px" }
const _hoisted_10 = ["href", "target"]
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = { class: "fs-7 fw-bold" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 1,
  "data-kt-search-element": "empty",
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_select, {
            "aria-label": "Select example",
            modelValue: _ctx.searchType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchType) = $event)),
            onChange: _ctx.reset,
            width: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchTypes, (searchType) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: searchType.value,
                  value: searchType.value,
                  label: searchType.label
                }, null, 8, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "search-input form-control form-control-flush ps-10",
            name: "search",
            onKeydown: [
              _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.searchToDown && _ctx.searchToDown(...args)), ["self"]), ["down"])),
              _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.getSearchEnter && _ctx.getSearchEnter(...args)), ["enter"]))
            ],
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
            placeholder: "Search...",
            "data-kt-search-element": "input",
            autocomplete: "off",
            ref: "focusHere",
            autofocus: ""
          }, null, 544), [
            [_vModelText, _ctx.search]
          ])
        ])
      ]),
      (_ctx.showLoader)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _cache[6] || (_cache[6] = [
            _createElementVNode("span", { class: "spinner-border h-15px w-15px align-middle text-gray-400" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _cache[11] || (_cache[11] = _createElementVNode("span", {
        class: "btn btn-flush",
        "data-kt-search-element": "clear"
      }, [
        _createElementVNode("span", { class: "svg-icon svg-icon-2 svg-icon-lg-1 me-0" }, [
          _createElementVNode("svg", { src: "icons/duotune/arrows/arr061.svg" })
        ])
      ], -1)),
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "separator border-gray-200 mb-6" }, null, -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchList, (result, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("a", {
                class: _normalizeClass(["d-flex text-dark text-hover-primary align-items-center mb-5 search-result fntwo", { disabled: _ctx.invoiceDisabled(result) }]),
                href: _ctx.goToNav(result, _ctx.searchUrl),
                target: !_ctx.invoiceDisabled(result) ? '_blank' : '',
                id: "search-first",
                onKeydown: [
                  _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.pressdown && _ctx.pressdown(...args)), ["down"])),
                  _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.pressup && _ctx.pressup(...args)), ["up"]))
                ]
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "symbol symbol-40px me-4" }, [
                  _createElementVNode("span", { class: "symbol-label bg-light" }, [
                    _createElementVNode("span", { class: "svg-icon svg-icon-2 svg-icon-primary" }, [
                      _createElementVNode("i", { class: "fa fa-users text-primary" })
                    ])
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, [
                    _createTextVNode(_toDisplayString(result.name) + " ", 1),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(result.email ? result.email + " | " : "") + " " + _toDisplayString(result.phone_number) + " ", 1),
                    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(result.merchant_reference) + " ", 1),
                    (_ctx.isBai || _ctx.userPermissions.is_underwritter)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(result.status || _ctx.isBai
                    ? " | " + _ctx.status(result.status, result)
                    : " | Payment pending"), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 42, _hoisted_10)
            ]))
          }), 128))
        ])
      ]),
      (_ctx.searchList && _ctx.searchList.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[10] || (_cache[10] = [
            _createStaticVNode("<div class=\"pt-10 pb-10\" data-v-5f762f91><span class=\"svg-icon svg-icon-4x opacity-50\" data-v-5f762f91><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" data-v-5f762f91><path opacity=\"0.3\" d=\"M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z\" fill=\"currentColor\" data-v-5f762f91></path><path d=\"M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z\" fill=\"currentColor\" data-v-5f762f91></path><rect x=\"13.6993\" y=\"13.6656\" width=\"4.42828\" height=\"1.73089\" rx=\"0.865447\" transform=\"rotate(45 13.6993 13.6656)\" fill=\"currentColor\" data-v-5f762f91></rect><path d=\"M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z\" fill=\"currentColor\" data-v-5f762f91></path></svg></span></div><div class=\"pb-15 fw-bold\" data-v-5f762f91><h3 class=\"text-gray-600 fs-5 mb-2\" data-v-5f762f91>No result found</h3><div class=\"text-muted fs-7\" data-v-5f762f91> Please try again with a different query </div></div>", 2)
          ])))
        : _createCommentVNode("", true)
    ])
  ], 512))
}