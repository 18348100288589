import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end mb-10" }
const _hoisted_2 = { class: "d-flex flex-column align-items-end" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = { class: "me-3" }
const _hoisted_5 = { class: "text-muted fs-7 mb-1" }
const _hoisted_6 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  class: "p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end",
  "data-kt-element": "message-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.time), 1),
          _cache[0] || (_cache[0] = _createElementVNode("a", {
            href: "#",
            class: "fs-5 fw-bolder text-gray-900 text-hover-primary ms-1"
          }, "You", -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("img", {
            alt: "Pic",
            src: _ctx.image
          }, null, 8, _hoisted_7)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}